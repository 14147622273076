<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <div
        :class="['detail', contrast ? 'contrast' : '']"
    >
        <Image
            :filePath="filePath"
            :alternativeText="alternativeText"
            :colour="contrast ? 'blue' : 'green'"
        />
        {{ label }}
    </div>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import Image from '@/components/generic/Image';

    //

    // COMPONENTS

        // Define components.
        const components = {
            Image
        };

    //

    // PROPS

        // Define props.
        const props = {
            filePath: {
                type: String,
                default: 'tick.svg'
            },
            alternativeText: {
                type: String,
                default: 'Tick'
            },
            label: {
                type: String,
                default: 'Undefined'
            },
            contrast: {
                type: Boolean
            }
        };

    //

    // EXPORT

        export default {
            name: 'Detail',
            components,
            props
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // CLASSES

        .detail {
            display: flex;
            align-items: center;
            gap: .5rem;
            font-size: 1rem;
            font-weight: 500;
        }

        .contrast {
            color: $colour-contrast
        }

    //

</style>
