/* Copyright (©) 2021. Care Control Systems Limited. All rights reserved. */


// METHODS

    // 'POST' request.
    export const postRequest = async (parameters) => {

        // Destructure parameters.
        const { target, endPoint, body } = parameters;

        // Construct request URL by combining the base URL with the end point value.
        const requestURL = target + endPoint;

        // For each element of body.
        const bodyString = ( () => {

            // Define array to store string values.
            const stringArray = [];

            // For each element of the body parameter.
            for (let i = 0; i < body.length; i++) {

                // Define alias.
                const element = body[i];

                // Push constructed string to array.
                stringArray.push(`${element.key}=${JSON.stringify(element.value)}`);

            }

            // Return joined string.
            return stringArray.join("&");

        })();

        // Invoke fetch request.
        const result = await fetch(requestURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: bodyString,
        });

        // If request successful.
        if (result.ok) {

            try {

                // Parse JSON.
                const JSONObject = await result.json();

                // Return JSON
                return JSONObject;

            } catch (error) {

                // DEBUG
                console.log(error);

            }
            
        }

        // If request failed, return false;
        return false;

    };

//


// EXPORT

    export default {
        postRequest
    };

//
