<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <OverlayComponent v-if="isActive">
        <div class="container">
            <header>
                {{ applicationOpened ? 'Redirected' : 'Redirecting...' }}
                <Button
                    :action="close"
                    class="close"
                    title="Close"
                >
                    <Image
                        filePath="cross.svg"
                        alternativeText="Close"
                        colour="white"
                    />
                </Button>
            </header>
            <section
                v-if="!applicationOpened"
                class="content"
            >
                You will be automatically redirected to the requested application in <span class="timer">{{ timeRemaining }}</span> seconds.
                <Button
                    :action="goToApplication"
                    title="Go To Application"
                    class="submit"
                >
                    <Image
                        filePath="apply.svg"
                        alternativeText="Apply"
                        class="icon"
                    />
                    Apply Now
                </Button>
            </section>
            <section
                v-else
                class="content"
            >
                <Image
                    filePath="new-tab.svg"
                    class="blue status"
                />
                The requested application has been opened in a new tab.
            </section>
        </div>
    </OverlayComponent>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import OverlayComponent from '@/components/application/overlay/OverlayComponent';
        import Button from '@/components/generic/Button';
        import Image from '@/components/generic/Image';

    //

    // COMPONENTS

        // Define components.
        const components = {
            OverlayComponent,
            Button,
            Image
        };

    //

    // PROPS

        // Define props.
        const props = {
            id: {
                type: String,
                required: true
            },
            siteURL: {
                type: String,
                required: true
            }
        };

    //

    // DATA

        // Define data.
        const data = function () {
            return {
                isActive: false,
                timer: null,
                currentTime: 0,
                timerLength: 3000,
                applicationOpened: false
            };
        };

    //

    // COMPUTED

        // Determine remaining time.
        const timeRemaining = function () {
            return (this.timerLength - this.currentTime) / 1000;
        };

        // Define computed.
        const computed = {
            timeRemaining
        };

    //

    // METHODS

        // Show.
        const show = function () {
            this.isActive = true;
        };

        // Close.
        const close = function () {
            this.isActive = false;
            this.applicationOpened = false;
        };

        // Go to Application.
        const goToApplication = function () {

            // Update application opened state.
            this.applicationOpened = true;

            // Construct URL and open in new browser tab.
            window.open(
                `${this.siteURL}?JobID=${this.id}`,
                '_blank'
            );

        };

        // Clear timer.
        const clearTimer = function () {

            // Reset current time.
            this.currentTime = 0;

            // Remove interval.
            if (this.timer) {
                clearInterval(this.timer);
            }
    
        };

        // Define methods.
        const methods = {
            show,
            close,
            goToApplication,
            clearTimer
        };

    //

    // WATCH

        // Define watch.
        const watch = {
            isActive: function () {

                // If active.
                if (this.isActive) {

                    this.goToApplication();

                    // Initialise timer.
                    /* this.timer = setInterval(() => {

                        // If time has elapsed.
                        if (this.currentTime === this.timerLength) {

                            // Invoke methods.
                            this.goToApplication();
                            this.clearTimer();

                        } else {

                            // Else, increment timer.
                            this.currentTime += 1000;

                        }

                    }, 1000); */

                } else {

                    // Invoke clear timer.
                    this.clearTimer();

                }

            }
        };

    //

    // EXPORT

        export default {
            name: 'JobApplication',
            components,
            props,
            data,
            computed,
            methods,
            watch
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // ELEMENTS

        header {
            display: flex;
            gap: 1rem;
            align-items: center;
            padding: 1rem;
            background-color: $colour-light;
            @extend .title;
            color: $colour-secondary;
            border-top-left-radius: .25rem;
        }

    //

    // CLASSES

        .container {
            width: 100%;
            max-height: 90vh;
            border-radius: .5rem;
            overflow: hidden;
            box-shadow: 0 .1rem .5rem $colour-shadow;
            background-color: $colour-light;
        }

        .close {
            background-color: $colour-warning;
            margin-left: auto;
        }

        .content {
            display: grid;
            gap: 1rem;
            padding: 1rem .75rem 1.5rem;
            margin: 0 .5rem;
            text-align: center;
        }

        .timer {
            background-color: $colour-contrast;
            color: $colour-light;
            border-radius: 50%;
            font-size: 1.5rem;
            padding: 1.5rem;
            text-align: center;
        }

        .timer, .status {
            width: 5rem;
            height: 5rem;
            justify-self: center;
        }

        .submit {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .5rem;
            min-height: 4rem;
            font-size: 1.1rem;
            font-weight: 500;
        }

    //

</style>
