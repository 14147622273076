<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <div class="overlay-component">
        <slot />
    </div>
</template>


<script>

    // DEPENDENCIES

        // Utilities.
        import { generateID } from '@/common/Utilities';

    //

    // DATA

        // Define data.
        const data = function () {
            return {
                id: null
            };
        };

    //

    // CREATED

        // Define mounted.
        const created = function () {

            // Update id value.
            this.id = generateID();

        };

    //

    // BEFORE MOUNT

        // Define before mount.
        const beforeMount = function () {

            this.$store.dispatch(
                'addOverlayComponent',
                this.id
            );

        };

    //

    // BEFORE UNMOUNT

        // Define before unmount.
        const beforeUnmount = function () {

            this.$store.dispatch(
                'removeOverlayComponent',
                this.id
            );

        };

    //

    // EXPORT

        export default {
            name: 'OverlayComponent',
            data,
            created,
            beforeMount,
            beforeUnmount
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // CLASSES

        .overlay-component {
            display: grid;
            align-items: flex-start;
            justify-items: center;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 12;
            padding: 1rem .5rem;
            @media screen and (min-width: $large) {
                padding: 5% 20%;
            }
        }

    //

</style>
