<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <div>Loading...</div>
</template>


<script>

    // EXPORT

        export default {
            name: 'Throbber'
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

</style>
